import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

import { SearchContextProvider } from '/machinery/SearchContext'

import { ArticlePageSingleSnackable } from '/pages/article-types/ArticlePage'

const queryClient = new QueryClient()

export default function ArticlePageSingleSnackableApp({ doc, regionData, issues },  { ...renderers } ) {
  return (
    <SearchContextProvider {...{ doc, regionData }} >
      <QueryClientProvider client={queryClient}>
        <ArticlePageSingleSnackable {...{ doc, regionData, issues }}  {...renderers} />
      </QueryClientProvider>
    </SearchContextProvider>
  )
}
